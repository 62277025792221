$active-blue: rgb(80, 160, 227);
$md: 768px;
$sm: 568px;

p {
  margin: 0;
}

.App {
  height: 100vh;
  background: #e9f4fa;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-weight: 200;

  .container {
    margin: auto;
    padding-top: 2rem;
    max-width: 600px;
    width: 100%;

    @media (max-width: $md) {
      width: 90%;
    }

    .cities {
      @media (max-width: $md) {
        display: flex;
        flex-direction: column;
      }

      button {
        background: none;
        border: none;
        font-size: 2rem;
        text-transform: uppercase;
        padding-inline: 1.5rem;
        font-family: 'Work Sans', sans-serif;
        font-weight: 200;
        transition: 0.2s all ease-in-out;

        &:hover {
          cursor: pointer;
          color: $active-blue;
          opacity: 0.8;
        }

        &.active {
          pointer-events: none;
          color: $active-blue;
          font-weight: 600;
        }
      }
    }

    .weather-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      border: 4px solid #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.1);

      .weather-info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: left;

        svg {
          margin-right: 1rem;
        }
      }

      svg {
        fill: #2b3f66;
      }

      .four-day-forecast {
        display: flex;
        flex-direction: row;
        width: 100%;

        .weather {
          &:last-child {
            @media (max-width: $sm) {
              display: none;
            }
          }
        }
      }
    }
  }
}